import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useUser } from "../../stores/useUserStore";

const StyledFooter = styled("footer")(() => ({
  display: "flex",
  position: "absolute",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  lineHeight: "20px",

  margin: "0px",
  width: "100%",
  height: "37px",
  padding: "9px",
  boxSizing: "border-box",
  background: "#E5E5E5",
  color: "#A0A0A0",
  bottom: "0px",
  fontFamily: "Nunito Sans, sans serif",
  fontSize: "10px",
  fontWeight: 400,
  right: 0,
  left: 0,
}));

const FooterLink = styled("a")(() => ({
  color: "#456bb0",
  textDecoration: "none",
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
    color: "#456bb0",
  },
}));

const FooterContent = styled("div")(() => ({
  background: "#e5e5e5",
  color: "#a0a0a0",
  fontSize: "10px",
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: 400,
  alignSelf: "center",
  textAlign: "center",
}));

export default function Footer() {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <FooterContent>
        <span>{t("FOOTER", { year })}</span>
        <FooterLink
          href={useUser() ? "#/privacy-policy" : "#/privacy-policy-no-login"}
          target="_blank"
          rel="noreferrer"
        >
          {t("PRIVACY_POLICY.FOOTER_LINK")}
        </FooterLink>
      </FooterContent>
    </StyledFooter>
  );
}

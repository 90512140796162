import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:9090"
      : `${window.location.protocol}//${window.location.host}`,
  headers: {
    get: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("remuneratio-bearer-token");
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (e) => {
    if (e.response.status === 403) {
      window.location = "/#/";
    }
    throw e;
  }
);

export default api;
